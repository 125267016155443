.App {
  text-align: center;
  background-color: var(--NIGHTOPS);
  color: var(--DUSTYGREY);
  font-size: 16px;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

a {
  color: var(--MUZZLEWHITE);
}

.bottom_msg {
  position: sticky;
  bottom: 0;
  height: 50px;
  color: var(--HORIZON);
  display: flex;
  justify-content: center;
  align-items: center;
}
