body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  display: none;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --NIGHTOPS: #0d0d0d;
  --HORIZON: #474f59;
  --GUNMETAL: #79818c;
  --DUSTYGREY: #b0b9bf;
  --MUZZLEWHITE: #ebeff2;
}

* {
  transition: color 0.5s linear, background-color 0.5s linear;
}
